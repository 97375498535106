






























import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import store from "@/store";
@Component({})
export default class realtimeOrder extends Vue {
  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  // 总条数
  total = 0;
  // 搜索条件
  search: any = {
    id: '',
    channelId: '',
    tradingCenterCode: '',
    created_at: [],
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };

  //获取交易中心
  channelList: any = {};
  channelDict() {
    api.post("J30122", { channelId: this.search.channelId }).then((res) => {
      this.channelList = res;
    });
  }


  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post("J30131", {
        ...this.search,
        tradingCenterCode: this.search.tradingCenterCode,
      })
      .then((data) => {
        this.total = data.total;
        this.tableData = data.list;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  // 订单明细
  goTo(item: any) {
    this.$router.push({
      path: "/order/orderDetails",
      query: {
        channelId: item.channelId,
        tradingCenterCode: item.tradingCenterCode,
        sendingOrganizationCode: item.sendingOrganizationCode,
      },
    });
  }


  activated() {
    console.log('store.state', store.state.user.user.id)
    this.search.id = store.state.user.user.id;
    this.search.channelId = this.$route.query.channelId;
    this.searchTable();
    this.channelDict()
  }
}
